import { Routes, addBasePath } from '@/utils/routes';
import { DjBodyText, DjHeading, DjStack } from '@paymentsense/dj-components-react';
import { i18n } from 'legacy-i18n';
import { DjpDojoIconPage } from 'legacy-stencil-react';
import Link from 'next/link';
import { linkStyle } from './pageNotFound.css';

const PageNotFound = () => {
  return (
    <DjpDojoIconPage iconLink={addBasePath(Routes.home)}>
      <DjStack>
        <DjHeading h="4">{i18n.t('error:error.not_found.heading')}</DjHeading>
        <DjBodyText>{i18n.t('error:error.not_found.body')}</DjBodyText>
        <Link className={linkStyle} href={Routes.home} as={addBasePath(Routes.home)} prefetch={false}>
          {i18n.t('error:error.back_to_home.action')}
        </Link>
      </DjStack>
    </DjpDojoIconPage>
  );
};

export default PageNotFound;
